.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

@media print {
  .not-printable {
    display: none
  }
  .end-of-page {
    display: block;
    page-break-after: always
  }
  .item-panel {
    page-break-inside: avoid;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .MuiGrid-container {
    display: -ms-inline-flexbox !important;
  }
}

@media (max-width: 600px) {
  .mobile-spacing {
    padding: 10px 0;
  }
}